import React, { useEffect, useState, useCallback } from 'react';
import { Button, Checkbox, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, InputLabel, Paper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Input from '../common/Input'
import * as actions from '../../containers/MultiSite/actions'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import Validation from '../../containers/Validation';
import { openGlobalDialog } from '../../containers/common/actions';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { isLoggedIn } from '../../utils';
import { getMscSites } from '../../containers/Admin/MultiSiteCompany/Sites/actions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialFilter = {
    limit: 25, serial_number: '', page: 1, sortBy: 'created_at', orderBy: 'desc',
}

const initialState = {
    site_name: '',
    site_address: '',
    site_manager_name: '',
    site_manager_email: '',
}

export default function AddSite(props) {
    const dispatch = useDispatch()
    const [data, setData] = useState({ ...initialState })
    const [errors, setErrors] = useState({})
    const [MscContactList, setMscContactList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoadingPagination, setIsLoadingPagination] = useState(false);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [open, setOpen] = React.useState(false);
    const [isLoading, setLoading] = useState(false);
    const { userType = null } = isLoggedIn();
    const [errorMessage, setErrorMessage] = useState("");
    const { multi_site_company_id = false } = props.globalModal.data;

    useEffect(() => {
        if (userType === "Admin" && !multi_site_company_id) {
            getMscContact();
        }
        if (multi_site_company_id) {
            setData({...data, multi_site_company_id })
        }
    }, []);

    const debouncedFetchData = useCallback(
        _.debounce((query) => {
            getMscContact(query);
        }, 500),
        []
    );

    const getMscContact = (query = '', currentPage = 1, currentContacts = []) => {
        dispatch(props?.globalModal?.data?.getMultiSiteContact({ company_name: query, page: currentPage, limit: 25 }))
            .then((res) => {
                if (currentPage > page) {
                    setMscContactList([...currentContacts, ...(res?.info?.data ?? [])]);
                } else {
                    setMscContactList(res?.info?.data || []);
                }
                setIsLoadingPagination(false);
                setPage(res?.info?.current_page);
                setLastPage(res?.info?.last_page);
            })
    }

    const onChangeGroupField = ({ target: { value = '', type = 'text', name = '' } }) => {
        if (type == 'text' && name !== "site_address" && value?.length > 60) return
        // if (name == 'site_manager_name' && value && !value.match(/^[a-zA-Z\s]*$/)) return
        setData({ ...data, [name]: value });
        setErrors({ ...errors, [name]: '' });
    }

    const onMSCChange = (event, value) => {
        if (value) {
            setData({ ...data, multi_site_company_id: value.id });
            setErrors({ ...errors, multi_site_company_id: '' })
        }
        else{
            setData({ ...data, multi_site_company_id: '' });
            setErrors({ ...errors, multi_site_company_id: 'Please Select MSC Contact' })
        }
    };

    const isValid = () => {
        let isValid = true;
        let error_msgs = {};
        if (userType === "Admin" && !multi_site_company_id) {
            if (!data.multi_site_company_id || data.multi_site_company_id.length == 0) {
                isValid = false;
                error_msgs.multi_site_company_id = 'Please Select MSC Contact';
            } else {
                error_msgs.multi_site_company_id = '';
            }
        }
        if (!data.site_name) {
            isValid = false;
            error_msgs.site_name = 'Site Name is required';
        } else {
            error_msgs.site_name = '';
        }

        if (!data.site_address) {
            isValid = false;
            error_msgs.site_address = 'Site Address is required';
        } else {
            error_msgs.site_address = '';
        }

        if (!data.site_manager_name) {
            isValid = false;
            error_msgs.site_manager_name = 'Site Manager Name is required';
        } else if (!data.site_manager_name.match(/^[a-zA-Z\s]*$/)) {
            isValid = false;
            error_msgs.site_manager_name = 'Please enter valid manager name';
        } else {
            error_msgs.site_manager_name = '';
        }

        if (!data.site_manager_email) {
            isValid = false;
            error_msgs.site_manager_email = 'Site Manager Email is required';
        } else if (!data.site_manager_email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            isValid = false;
            error_msgs.site_manager_email = 'Please enter a valid email address';
        } else {
            error_msgs.site_manager_email = '';
        }
        setErrors(error_msgs);
        return isValid;
    }

    const onSubmit = () => {
        setErrorMessage('');
        if (isValid()) {
            setLoading(true);
            dispatch(props?.globalModal?.data.addSite({...data,site_name:data?.site_name?.trim()})).then(res => {
                setLoading(false);
                if (props?.globalModal?.data.openModalCheck) {
                    dispatch(actions.getAllMultiSiteSite());
                    dispatch(openGlobalDialog({
                        addDevices: props?.globalModal.data.addDevices
                    }, 'ADD_TO_SITE_MODAL'))
                } else {
                    if(userType == "Admin"){
                        dispatch(getMscSites());
                        props?.closeModal();
                    }
                    else if(userType == "mscc"){
                        dispatch(actions.getAllMultiSiteSite());
                        props?.closeModal();
                    }
                    props?.closeModal();
                }
                dispatch(props?.globalModal?.data.getSites())
            }).catch(err => {
                setLoading(false);
                setErrorMessage(err && err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Something went wrong');
            })
        }
    }

    // Handle input change for search
    const handleInputChange = (event, value) => {
        setSearchQuery(value.trim());
        setIsLoadingPagination(true);
        debouncedFetchData(value.trim());
    };

    const CustomPaper = (props) => {
        return <Paper {...props} style={{ border: '1px solid black', borderRadius: '4px' }} />;
    };

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        if (
            listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight &&
            !isLoadingPagination &&
            page < lastPage
        ) {
            setIsLoadingPagination(true);
            getMscContact(searchQuery, page + 1, MscContactList);
        }
    };

    return (
        <>
                <DialogTitle className="heat_pump_modal_title">
                    <Grid item md={12} className="d-flex">
                        {props.globalModal.data.openModalCheck ? <span className='mr-1'
                                onClick={
                                    () => dispatch(openGlobalDialog({
                                        addDevices : props.globalModal.data.addDevices,
                                        addSite: props.globalModal.data.addMultiSiteToSite,
                                    }, 'ADD_TO_SITE_MODAL'))
                                }
                            ><ArrowBackIcon className='show-pointer' /> </span> : ''}
                            <h6>New Site</h6>
                        </Grid>
                </DialogTitle>
                <DialogContent dividers className='heat_pump_dialog_content'>
                    {userType == 'Admin' && !multi_site_company_id && (
                        <Grid item md={12} className='mb-3'>
                            <InputLabel className="inputlabelHeatPumpModal">
                                Select Multi Site Company
                            </InputLabel>
                            <Autocomplete
                                id="asynchronous-demo"
                                sx={{ width: 300 }}
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={(event, reason) => {
                                    if (reason !== 'blur') {
                                        setOpen(false); // Close only when clicking outside
                                    }
                                }}
                                ListboxProps={{
                                    onScroll: handleScroll, // Attach the scroll event handler for infinite scrolling
                                }}
                                name="multi_site_company_id"
                                isOptionEqualToValue={(option, value) => option.company_name === value.company_name}
                                getOptionLabel={(option) => option.company_name}
                                options={MscContactList?.filter((data) => data.company_name) || []}
                                // loading={loading}
                                onInputChange={handleInputChange}
                                onChange={onMSCChange}
                                loading={isLoadingPagination}
                                // loadingText={<CircularProgress size={24} />}
                                // PaperComponent={CustomPaper}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label=""
                                        className="inputfieldHeatPumpModalRemove"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {isLoadingPagination ? <CircularProgress className='heatpump_dropdownloader' color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            {errors.multi_site_company_id && <span className="profile-error">{errors.multi_site_company_id}</span>}
                        </Grid>
                    )}

                    <Grid item md={12} className="mb-3">
                        <InputLabel className="inputlabelHeatPumpModal">
                            Site Name
                        </InputLabel>
                        <Input
                            variant="outlined"
                            fullWidth
                            name="site_name"
                            label=""
                            className="inputfieldHeatPumpModal"
                            type="text"
                            value={data.site_name || ""}
                            onChange={onChangeGroupField}
                        />
                        {errors.site_name && <span className="profile-error">{errors.site_name}</span>}
                    </Grid>
                    <Grid item md={12} className="mb-3">
                        <InputLabel className="inputlabelHeatPumpModal">
                            Site Address
                        </InputLabel>
                        <Input
                            variant="outlined"
                            fullWidth
                            name="site_address"
                            className="inputfieldHeatPumpModal"
                            label=""
                            type="text"
                            value={data.site_address || ""}
                            onChange={onChangeGroupField}
                        />
                        {errors.site_address && <span className="profile-error">{errors.site_address}</span>}
                    </Grid>
                    <Grid item md={12} className="mb-3">
                        <InputLabel className="inputlabelHeatPumpModal">
                            Site Manager Name
                        </InputLabel>
                        <Input
                            variant="outlined"
                            fullWidth
                            name="site_manager_name"
                            label={``}
                            className="inputfieldHeatPumpModal"
                            type="text"
                            value={data.site_manager_name || ""}
                            onChange={onChangeGroupField}
                        />
                        {errors.site_manager_name && <span className="profile-error">{errors.site_manager_name}</span>}
                    </Grid>
                    <Grid item md={12} className="">
                        <InputLabel className="inputlabelHeatPumpModal">
                            Site Manager Email
                        </InputLabel>
                        <Input
                            variant="outlined"
                            fullWidth
                            name="site_manager_email"
                            className="inputfieldHeatPumpModal"
                            type="email"
                            value={data.site_manager_email || ""}
                            onChange={onChangeGroupField}
                        />
                        {errors.site_manager_email && <span className="profile-error">{errors.site_manager_email}</span>}
                    </Grid>
                    <Grid item md={12} className="mb-3">
                        {errorMessage && <span className="profile-error">{errorMessage}</span>}
                    </Grid>
                </DialogContent>
                <Grid>
                    <DialogActions className='heat_pump_dialog_action'>

                        <Button className={`cus-btn-modal-submit ${isLoading ? "disabled" : ""}`} onClick={isLoading ? null : onSubmit}>
                            {isLoading ? <CircularProgress size={18} color='white' /> : 'Add'}
                        </Button>
                    </DialogActions>
                </Grid>
        </>
    )
}

