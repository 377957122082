import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import TapAndPlayIcon from "@material-ui/icons/TapAndPlay";
import { getDeviceHistoryBySerialNo, getFaultyHistory } from "../actions";
import {
  dateTimeWithoutGMT,
  dateWithoutTime,
  dateWithTime,
  formatDateTime,
  formatTime,
} from "../../../../utils";
import { clearStoreForDevice } from "../../Devices/actions";
import Papa from "papaparse";
import { openSnackbarsState } from "../../../common/actions";
function FaultyHistory(props) {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { faultHistory = {}, isLoading = false } = useSelector(
    (state) => state.faultySystemsReducer
  );
  const [exportLoading, setExportLoading] = useState(false);

  const exportCSV = () => {
    let flattenedData = [];
    // Flatten the data and extract headers
    setExportLoading(true);
    if (faultHistory?.length) {
      flattenedData = faultHistory?.flatMap((entry) => ({
        "Device ID": entry?.device_id,
        Date: dateWithoutTime(entry?.history_datetime),
        Time: formatTime(entry?.history_datetime),
        Email: entry?.customer?.email || entry?.heatpump?.customer?.email || "-",
        "Fault Code": entry?.fault_code,
        "Fault Description": entry?.fault_description,
        "Fault Status": entry?.fault_status === 1 ? "Resolved" : "Unresolved",
        Agent: entry?.agent,
        Comments: entry?.job_comments,
      }));
    }

    if (!flattenedData.length) {
      dispatch(openSnackbarsState({ message: "NO Records Found", messageType: "error" }));
      setExportLoading(false);

      return;
    }
    let csv; // Bold headers
    csv = Papa.unparse(flattenedData); // Data without headers

    // Create a blob and a link to download it
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `faultHistory.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setExportLoading(false);
  };

  useEffect(() => {
    let id = params?.id || false;
    dispatch(getFaultyHistory(id))
      .then((res) => {})
      .catch((err) => {
        history.goBack();
      });
    return () => {
      dispatch(clearStoreForDevice());
    };
  }, []);

  return (
    <div>
      <Grid container className="location-container">
        <Grid item xs={12}>
          <Grid container className="location-head mb-4 con-space-between">
            <div className="d-flex align-items-center">
              <div className="back-btn">
                <div className="back-icon back-btn" onClick={() => history.goBack()}>
                  <ArrowBackIosIcon />
                </div>
              </div>
              <h2>
                Fault History{" "}
                <span className="mt-1 ml-1">{params?.id ? `[${params?.id}]` : null}</span>
              </h2>
            </div>
            <Button className="button-btn btn-custom-primary" onClick={exportCSV}>
              {!exportLoading ? "Export" : <CircularProgress size={18} color="white" />}
            </Button>
          </Grid>
        </Grid>
        <Grid className="boxshadow-custom location-container common-add-component location-head">
          <Grid container className={faultHistory?.length ? "timeline" : ""}>
            {isLoading ? (
              <div className="update-circular-loader">
                <img src="/images/ep-loader.gif" className="loader" />
              </div>
            ) : faultHistory?.length ? (
              faultHistory.map((res, index) => {
                let {
                  agent,
                  device_id,
                  history_datetime,
                  fault_code,
                  customer,
                  fault_description,
                  fault_status,
                  id,
                  job_comments,
                  heatpump,
                } = res;
                // const { status = '', email = '' } = customer || {}
                // const { property_name = '', id = '', customer_id } = property_data || {}
                if (fault_status === "In-active") {
                  fault_status = "Inactive";
                }
                // agent, device_id, fault_code, fault_description, fault_status, id, job_comments
                return (
                  <Grid container className="event" key={index}>
                    {/* <Grid item md={4} sm={6} xs={12} className="px-2">
                                            <label>Id</label>
                                            <Typography className="input-text">{id || "-"}</Typography>
                                        </Grid> */}

                    {/* <Grid item md={4} sm={6} xs={12} className="px-2">
                                            <label>Device Id</label>
                                            <Typography className="input-text">{device_id || "Present"}</Typography>
                                        </Grid> */}

                    <Grid item md={4} sm={6} xs={12} className="px-2">
                      <label>Fault Code</label>
                      <Typography className="input-text">{fault_code || "-"}</Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="px-2">
                      <label>Fault Description</label>
                      <Typography className="input-text">{fault_description || "-"}</Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="px-2">
                      <label>Email</label>
                      <Typography className="input-text">
                        {customer?.email || heatpump?.customer?.email || "-"}nnn
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="px-2">
                      <label>Fault Status</label>
                      <Typography className="input-text">
                        {fault_status === 1 ? "Resolved" : "Unresolved"}
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="px-2">
                      <label>Date & Time</label>
                      <Typography className="input-text">
                        {formatDateTime(history_datetime) || "-"}
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="px-2">
                      <label>Agent</label>
                      <Typography className="input-text">{agent || "-"}</Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="px-2">
                      <label>Job Comments</label>
                      <Typography className="input-text">{job_comments || "-"}</Typography>
                    </Grid>
                    {/* {property_exists ? <Grid item md={4} sm={6} xs={12} className="px-2">
                                            <label>Property Name</label>
                                            <Typography className="input-text">{property_name || "-"}</Typography>
                                        </Grid> :
                                            (
                                                <>
                                                    <Grid item md={4} sm={6} xs={12} className="px-2">
                                                        <label>Property ID</label>
                                                        <Typography className="input-text">{id || "-"}</Typography>
                                                    </Grid>
                                                    <Grid item md={4} sm={6} xs={12} className="px-2">
                                                        <label>Customer ID</label>
                                                        <Typography className="input-text">{customer_id || "-"}</Typography>
                                                    </Grid>
                                                </>
                                            )
                                        }
                                        {
                                            property_exists && <Grid item md={4} sm={6} xs={12}>
                                                <Button className="button-btn btn-custom-primary" onClick={() => history.push(`/admin/customer/property/view/${id}`)}>Property Details</Button>
                                            </Grid>
                                        } */}
                  </Grid>
                );
              })
            ) : (
              <div className="no-data-found d-flex text-center p-3">No Fault Recorded Yet.</div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default FaultyHistory;
