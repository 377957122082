import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Input from "../common/Input";
import * as actions from "../../containers/Agency/AgencyDevices/actions";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../containers/Validation";
import { openGlobalDialog } from "../../containers/common/actions";
import TableLoader from "../common/TableLoader";
import { Add, Search } from "@material-ui/icons";
import addIcon from "../../assets/image/addIcon.svg";
import searchIcon from "../../assets/image/searchIcon.svg";
import { isLoggedIn } from "../../utils";
import { getAllMultiSiteCompanies } from "../../containers/Admin/HeatPumpDevices/actions";
import { assignAddToSiteDevices } from "../../containers/MultiSite/actions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialFilter = {
  contact_person_email: "",
};

export default function MultisiteModal(props) {
  const { addDevices } = props?.globalModal.data || {};
  const { userType = null } = isLoggedIn();

  const dispatch = useDispatch();
  const { allMultisites = {}, allMscLoading = false } = useSelector(
    (state) => state.heatPumpDevices
  );
  const {
    mscListLoading = false,
    agency_msc = [],
    isSubmitBtnloading = false,
  } = useSelector((state) => state?.agencyDevice);
  const { data, total = 0 } = userType === "Admin" ? allMultisites : agency_msc;

  const [filters, setfilters] = useState({ ...initialFilter });
  const [allSites, setAllSites] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState();
  const [errors, setErrors] = useState({});

  const filterCompanies = (filters) => {
    if (userType === "Admin") {
      dispatch(getAllMultiSiteCompanies(filters));
    } else {
      dispatch(actions.getAllAgencyMultiSiteCompanies(filters));
    }
  };

  const delaySearch = useCallback(
    _.debounce((filters) => {
      const existingSites = allSites?.filter((item) => {
        return item?.contact_person_email?.includes(filters?.contact_person_email);
      });
      if (!filters?.contact_person_email || existingSites?.length <= 0) {
        filterCompanies(filters);
      } else if (existingSites?.length > 0) {
        setAllSites(existingSites || []);
      }
    }, 500),
    [data, allSites]
  );

  const onChangeGroupField = ({ target: { value = "", checked = false, name = "" } }) => {
    if (name == "contact_person_email" && value?.length > 60) return;
    setfilters({ ...filters, contact_person_email: value });
    setErrors({ ...errors, contact_person_email: "" });
    delaySearch({ ...filters, contact_person_email: value });
    // if (name === "selectedAll") {
    //     setData({ ...data, [name]: checked })
    //     setErrors({ ...errors, selectedDevice: '' })
    // } else {
    // }
  };

  const isValid = () => {
    let isValid = true;
    let error_msgs = {};

    if (selectedDevice !== "") {
      error_msgs.company_name = "";
    } else {
      isValid = false;
      error_msgs.company_name = "Please Select Mulitisite";
    }
    setErrors(error_msgs);
    return isValid;
  };

  const onSubmit = () => {
    if (isValid()) {
      if (userType === "Admin") {
        dispatch(
          openGlobalDialog(
            {
              addDevices,
              selectedMsc: selectedDevice,
            },
            "ADD_TO_SITE_MODAL"
          )
        );
      } else {
        addDevices(selectedDevice).then(() => {
          props.closeModal();
        });
      }
    }
  };

  const selectItem = (itemId) => {
    if (selectedDevice == itemId) {
      setErrors({ company_name: "Please Select a Multisite" });
      setSelectedDevice("");
    } else {
      setSelectedDevice(itemId);
      setErrors({ company_name: "" });
    }
  };

  useEffect(() => {
    filterCompanies(filters);
  }, []);

  useEffect(() => {
    if (data) {
      setAllSites(data || []);
    }
  }, [data]);

  return (
    <>
      <DialogTitle className="heat_pump_modal_title">
        <Grid item md={12} className="d-flex align-items-center con-space-between gap-2">
          <span className="multi-site-head">Choose Multi-Site for the selected devices</span>
          <Button
            className="cus-btn-modal-head"
            variant="outlined"
            onClick={() =>
              dispatch(
                openGlobalDialog(
                  {
                    openCheckModal: true,
                    addDevices: addDevices,
                  },
                  "ADD_MULTISITE_MODAL"
                )
              )
            }>
            <Add style={{ width: "20px", height: "20px" }} />
            Add New Multi-Site
          </Button>
        </Grid>
      </DialogTitle>

      <DialogContent dividers className="heat_pump_dialog_content">
        <TextField
          className="heat_pump_dialog_content_searchbar"
          placeholder={"Search for multi-site"}
          variant="filled"
          fullWidth
          name="contact_person_email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="srhIcon">
                <img src={searchIcon} />
              </InputAdornment>
            ),
          }}
          value={filters.contact_person_email}
          onChange={onChangeGroupField}
        />
        {errors.contact_person_email && (
          <span className="profile-error">{errors.contact_person_email}</span>
        )}
        <Grid item md={12} className="">
          {(userType === "Admin" && allMscLoading) || mscListLoading ? (
            <TableLoader />
          ) : allSites && allSites.length > 0 ? (
            <List dense className="heat_pump_dialog_content_list">
              {allSites.map((item, i) => (
                <Card className="mb-2 mt-0 p-0" variant="outlined">
                  <CardContent className="p-0">
                    <ListItem
                      key={i}
                      button
                      className={`${selectedDevice == item.id ? "listItemSelected" : ""}`}
                      onClick={() => selectItem(item.id)}>
                      <ListItemText
                        id={`checkbox-list-secondary-label-${i}`}
                        primary={
                          <>
                            <p className="text-left">
                              {" "}
                              {item.company_name} ({item.contact_person_email})
                            </p>
                            <span className="">
                              {item.company_address}
                              {item.sites_count ? ` | ${item.sites_count} sites` : ""}
                            </span>
                          </>
                        }
                      />
                      {selectedDevice == item.id ? (
                        <ListItemSecondaryAction>
                          <input
                            type="checkbox"
                            className="heat_pump_checkbox"
                            edge="end"
                            name="selectDevice"
                            value={item.id}
                            checked={selectedDevice == item.id}
                            onChange={(e) => {
                              if (!e.target.checked) {
                                setSelectedDevice("");
                                setErrors({ company_name: "Please Select Multisite" });
                              } else {
                                setSelectedDevice(item.id);
                                setErrors({});
                              }
                            }}
                            inputProps={{
                              "aria-labelledby": `checkbox-list-secondary-label-${i}`,
                            }}
                          />
                        </ListItemSecondaryAction>
                      ) : null}
                    </ListItem>
                  </CardContent>
                </Card>
              ))}
            </List>
          ) : (
            <p className="text-center mt-40 no-data-modal-text">No Data Found</p>
          )}
        </Grid>
      </DialogContent>
      <Grid>
        <DialogActions className="heat_pump_dialog_action">
          <Button
            className={`cus-btn-modal-submit ${!selectedDevice ? "disabled" : ""}`}
            onClick={isSubmitBtnloading ? null : onSubmit}>
            {isSubmitBtnloading ? (
              <CircularProgress size={18} color="white" />
            ) : userType === "Admin" ? (
              "Next"
            ) : (
              "Assign"
            )}
          </Button>
        </DialogActions>
      </Grid>
    </>
  );
}
