import { apiGet, apiPost, apiPut, getDeviceType, isLoggedIn } from "../../../utils";
import moment from "moment";
const { userType } = isLoggedIn();

const generateUrlWithAdminType = (userType, url) => {
  return `/api${userType === "Admin" ? "/" : `/${userType?.toLowerCase()}/`}${url}`;
};

export function getAllAgencyDevicesAPI(filters) {
  let arr = [];

  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen") continue;
    if (typeof value !== "number" && !value?.length) continue;

    if (key === "installation_date") {
      if (value[0] == null) continue;
      arr.push({
        key,
        value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
        end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
        type: "single",
      });
    } else if (["device_status", "status"].includes(key)) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value, type: "single" });
    }
  }
  return apiGet(
    `/api/agency/devices/all?deviceType=${getDeviceType()}&filter=${JSON.stringify(arr)}`
  );
}

export function getAgencyDeviceDetailAPI(id) {
  return apiGet(
    `${generateUrlWithAdminType(
      userType,
      "device/get-info"
    )}?deviceType=${getDeviceType()}&device_id=${id}`
  );
}

export function downloadCsvAPI(ids, csvtype, allDevice) {
  let obj = {};
  obj = {
    ...obj,
    csvtype: csvtype,
    all: allDevice?.allSelected === true ? true : false,
    device_uid: allDevice?.allSelected === true ? [] : ids,
    group: "agency",
  };
  return apiPost("/api/agency/devices/download-csv", obj);
}

export function updateDeviceDetailAPI(data) {
  const { id = "", registeredAddress = {}, tariffDetail = {}, deviceDetail = {}, ids = {} } = data;
  let { impulse_rate = "", impulse_rate_type = "", property_id = "", NMI } = deviceDetail;
  if (impulse_rate_type === "Wh/imp") {
    impulse_rate = 1000 / impulse_rate;
  }

  let deviceDetailData = {
    impulse_rate,
    impulse_rate_type,
    id: deviceDetail?.id,
    property_id,
    NMI,
  };
  let obj = {
    agency_id: ids?.agency_id,
    agent_id: ids?.agent_id,
    id: ids?.id,
    property_id: ids?.property_id,
    device: deviceDetailData,
    property: {
      id: ids?.property_id,
      property_name: registeredAddress?.property_name || "",
      unit_number: registeredAddress?.unit_number || "",
      street_type: registeredAddress?.street_type?.value || "",
      street: registeredAddress?.street || "",
      city: registeredAddress?.city || "",
      unit_type: registeredAddress?.unit_type?.value || "",
      level_type: registeredAddress?.level_type?.value || "",
      level_number: registeredAddress?.level_number || "",
      street_number: registeredAddress?.street_number || "",
      street_suffix: registeredAddress?.street_suffix?.value || "",
      state: registeredAddress?.state?.value || "",
      postal_code: registeredAddress?.postal_code || "",
      customer_tariff_details: {
        property_id: ids?.property_id,
        tariff_structure_id: tariffDetail?.selectedTarrifStructure?.value || "",
        electricity_retailer_id: tariffDetail.seletedElectricityRetailer?.value || "",
        gst_include: tariffDetail.gstIncluded === true ? 1 : 0,
        discount_percentage: tariffDetail?.discountPercent ? tariffDetail?.discountPercent : 0,
        discount_type: tariffDetail?.discount_type,
        supply_charge: tariffDetail?.supplyCharge ? tariffDetail?.supplyCharge : 0,
        supply_charge_discount: tariffDetail?.supplyChargeDiscount ? 1 : 0,
        impluse_rate: tariffDetail?.impluse_rate,
        impulse_rate_type: tariffDetail?.impulse_rate_type,
        unit_charge: tariffDetail?.isFlatRate ? tariffDetail?.costPerKwh : 0,
        same_as_weekdays: 0,
        company_id: tariffDetail?.selectedElectricityDistributor?.value || "",
        company_name: tariffDetail?.selectedElectricityDistributor?.title || "",
        is_flat_rate: tariffDetail?.isFlatRate,
        tariff_structure_type: tariffDetail?.selectedTarrifStructure?.title || "",
        tariff_plan: !tariffDetail?.isFlatRate
          ? tariffDetail?.tariffPlan?.length
            ? tariffDetail.tariffPlan.map((res) => ({
                key: res.key,
                load_type_key: res.load_type_key,
                unit_charge: res.unit_charge || "",
              }))
            : []
          : [],
      },
    },
  };

  return apiPost(`/api/agency/device/update?deviceType=${getDeviceType()}`, obj);
}

export function getAgencyDeviceHistoryBySerialNoAPI(mac_address, type, name, id, date) {
  let { term = "", endDate = "" } = date;
  // if (!term && type) return Promise.resolve()

  if (term) term = moment(date.term).format("YYYY-MM-DD");
  if (endDate) endDate = moment(date.endDate).format("YYYY-MM-DD");
  const userRole = userType.toLowerCase();
  if (type && type !== "activity-logs") {
    return apiGet(
      `https://stage.api.sync.flashdata.ihd.eems.app/heat-pump/${type}?${name}=${id}&start_date=${term}&end_date=${endDate}`,
      null,
      ""
    );
  } else if (type == "activity-logs") {
    return apiGet(
      `/api/${userRole}/heat-pump/${type}?${name}=${id}&start_date=${term}&end_date=${endDate}&source=nms`,
      null,
      "safelink"
    );
  }
  return apiGet(`/api/heat-pump/install-history?mac_address=${mac_address}`, null, "safelink");
}

export function getMultiSiteCompaniesAPI(filters = []) {
  let arr = [];
  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen") continue;
    if (typeof value !== "number" && !value?.length) continue;

    if (["status"].includes(key)) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value, type: "single" });
    }
  }
  return apiGet(`/api/agency/nms/msc/get/all?filter=${JSON.stringify(arr)}`);
}

export function assignMultiSiteDevicesApi(data) {
  return apiPost("/api/agency/nms/msc/assign-devices", data);
}
