import { useEffect, useState, useCallback, useRef } from "react";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Tab,
  Table,
  TableContainer,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import qs from "query-string";
import _ from "lodash";
import moment from "moment";
import FilterListIcon from "@material-ui/icons/FilterList";
import CustomDrawer from "../../../components/common/CustomDrawer";
import CusBackdrop from "../../../components/common/Backdrop";
import * as actions from "./actions";
import SearchFilterButtons from "../../../components/common/SearchFilterButtons";
import CustomAccordion from "../../../components/common/CustomAccordion";
import CustomSearchBar from "../../../components/common/CustomSearchBar";
import { downloadFile, trimFilters } from "../../../utils";
import DateRangePickerRsuit from "../../../components/common/DateRangePicker";
import EnhancedTableHead from "../../../components/common/EnhancedTableHead";
import TableLoader from "../../../components/common/TableLoader";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import TableSearch from "../Dashboards/common-comp-hp/TableSearch";
import SiteTable from "../Dashboards/common-comp-hp/SiteTable";
import HeatPumpTablePagination from "../../../components/common/HeatPumpTablePagination";
import * as socketAction from "../../../containers/commonPages/actions";
import { CloseIcon } from "react-material-toast/dist/Icon";

const initialFilter = {
  isOpen: false,
  mac_address: "",
  serial_number: "",
  wifi_name: "",
  soft_version: "",
  installed_via: "",
  agency_name: "",
  first_name: "",
  installer_uuid: "",
  device_status: [],
  status: [],
  installation_date: [null, null],
  id: "",
  limit: 25,
  page: 1,
  sortBy: "",
  orderBy: "desc",
  connection_type: [],
};

const options = ["Latest", "Oldest"];

const initialAccordion = {
  serialNumberAcc: false,
  modelNumberAcc: false,
  faultCodeAcc: false,
  customerEmailAcc: false,
  deviceWifiStatusAcc: false,
  deviceOperationAcc: false,
  resolvedStatusAcc: false,
  // macAddressAcc: false,
  // wifiAcc: false,
  // firmwareAcc: false,
  // installationDateAcc: false,
  // installedViaAcc: false,
  // agencyNameAcc: false,
  // fullNameAcc: false,
  // installer_uuidAcc: false,
  // deviceStatusAcc: false,
  // statusAcc: false,
  // idAcc: false
};

const manageHead = [
  {
    id: "serial_number",
    sortByKey: "serial_number",
    label: "Fault Devices SNo.",
    className: "show-pointer",
  },
  { id: "mac_address", sortByKey: "mac_address", label: "Model Number", className: "show-pointer" },
  { id: "soft_version", sortByKey: "soft_version", label: "Fault Code", className: "show-pointer" },
  {
    id: "wifi_name",
    sortByKey: "wifi_name",
    label: "Device Wi-Fi Status",
    className: "show-pointer",
  },
  {
    id: "heatpump_device_status",
    sortByKey: "postal_code",
    label: "Device Operation Status",
    className: "show-pointer",
    isMore: true,
  },
  {
    id: "connection_type",
    sortByKey: "connection_type",
    label: "Customer Email",
    className: "show-pointer",
  },
  {
    id: "installation_date",
    sortByKey: "installation_date",
    label: "Resolved/Unresolved",
    className: "show-pointer",
  },
  { id: "action", sortByKey: "action", label: "Action", className: "show-pointer" },
];

function FaultySystems(props) {
  const useRefFaulty = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { allFaultySystem = {}, isLoading = false } = useSelector(
    (state) => state.faultySystemsReducer
  );
  const { data: devices = [], total = 0 } = allFaultySystem;
  const [refresh, setRefresh] = useState(false);
  const [isDownloadingCsv, setDownloadingCsv] = useState(false);
  const [goToPage, setGoToPage] = useState("");
  const [newFilters, setNewFilters] = useState({ ...initialFilter });
  const [accordion, setAccordian] = useState({ ...initialAccordion });
  const [clearFilterLoader, setClearFilterLoader] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [newFault, setNewFault] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const delaySearch = useCallback(
    _.debounce((filters) => refreshHeatPumpDevices(filters), 500),
    []
  );
  const delayGoToPagination = useCallback(
    _.debounce((filters) => goToPageFun(filters), 1000),
    []
  );

  const handleTabChange = (e, tab) => {
    setActiveTab(tab);
    setNewFilters({ ...initialFilter });
  };

  const refreshHeatPumpDevices = (filters) => {
    dispatch(actions.getallfaultySystems(filters));
  };

  const goToPageFun = (value) => {
    refreshHeatPumpDevices(value);
  };

  const onChangeDate = (item) => {
    setNewFilters({ ...newFilters, installation_date: item });
    // refreshHeatPumpDevices({ ...newFilters, installation_date: item })
  };

  const onClickRefresh = () => {
    setRefresh(true);
    dispatch(actions.getallfaultySystems(newFilters))
      .then(() => setRefresh(false))
      .catch((err) => setRefresh(false));
  };

  const onPageChange = (e, page) => {
    refreshHeatPumpDevices({ ...newFilters, page });
    // setfilters({ ...filters, page })
    setNewFilters({ ...newFilters, page });
  };

  const onChangeLimit = (e) => {
    refreshHeatPumpDevices({
      ...newFilters,
      limit: e.target.value,
      page: 1,
      sortBy: "created_at",
      orderBy: "desc",
    });
    setNewFilters({ ...newFilters, limit: e.target.value, page: 1 });
    setGoToPage("");
  };

  const onSortBy = (label) => {
    if (label === "action") return;
    let orderBy = "";
    if (newFilters.orderBy === "desc") {
      orderBy = "asc";
    } else {
      orderBy = "desc";
    }
    const filters = { ...newFilters, sortBy: label, orderBy: orderBy };
    setNewFilters(filters);
    refreshHeatPumpDevices(filters);
  };

  const goToPropertyPage = (propertyId) => {
    if (propertyId) {
      history.push(`/admin/customer/property/view/${propertyId}`);
    }
  };
  const onChangeGoToPage = ({ target: { value = "" } }) => {
    if (!value.match(/^[0-9]*$/) || value === "0") return;
    let pageValue = Math.ceil(total / newFilters.limit);
    if (value > pageValue) return;
    setGoToPage(value ? parseInt(value) : "");
    setNewFilters({ ...newFilters, page: value ? parseInt(value) : "" });
    delayGoToPagination({ ...newFilters, page: value });
  };

  const openFilterSidebar = () => {
    setNewFilters({ ...newFilters, isOpen: true });
  };

  const onCloseFiltersDrawer = () => {
    setNewFilters({ ...newFilters, isOpen: false });
  };

  const handleChangeAccordion = (panel, filterKey) => (event, isExpanded) => {
    if (!isExpanded) {
      if (panel === "installationDateAcc") {
        setNewFilters({ ...newFilters, [filterKey]: [null, null] });
      } else {
        setNewFilters({
          ...newFilters,
          [filterKey]: ["statusAcc", "deviceStatusAcc"].includes(panel) ? [] : "",
          page: newFilters[filterKey]?.length ? 1 : newFilters.page,
        });
      }
    }
    setAccordian({ ...accordion, [panel]: isExpanded });
  };

  const onChangeFilter = ({ target: { name = "", value = "" } }) => {
    if (["mac_address", "serial_number"].includes(name) && value) {
      if (!value.match(/^[a-z0-9]+$/i)) return;
    }
    let tempFilter = { ...newFilters };
    tempFilter = { ...tempFilter, [name]: value, page: 1 };
    setNewFilters(tempFilter);
    // delaySearch({ ...tempFilter })
  };
  const onSearch = (name, value) => {
    let tempFilter = { ...newFilters };
    tempFilter = { ...tempFilter, [name]: value, page: 1 };
    setNewFilters(tempFilter);
    delaySearch({ ...tempFilter });
  };
  const onChangeStatus = (key, value) => {
    let tempNewFilter = { ...newFilters };
    if (tempNewFilter[key]?.includes(value)) {
      let index = tempNewFilter[key].indexOf(value);
      tempNewFilter[key]?.splice(index, 1);
    } else {
      tempNewFilter[key]?.push(value);
    }

    tempNewFilter = { ...tempNewFilter, page: 1 };
    setNewFilters(tempNewFilter);
    // refreshHeatPumpDevices(tempNewFilter)
  };

  const onChangeKeyDown = (e) => {
    if (e.key === "Enter") {
      refreshHeatPumpDevices({ ...trimFilters(newFilters), page: 1 });
    }
  };

  const clearFilter = () => {
    setClearFilterLoader(true);
    let tempFilter = {
      isOpen: newFilters.isOpen,
      mac_address: "",
      connection_type: [],
      device_status: [],
      status: [],
      installation_date: [null, null],
      limit: 25,
      page: 1,
      sortBy: "",
      orderBy: "desc",
      serial_number: "",
      soft_version: "",
      wifi_name: "",
    };

    setNewFilters({ ...tempFilter });
    setAccordian({ ...initialAccordion });
    dispatch(actions.getallfaultySystems(tempFilter)).then((res) => {
      setClearFilterLoader(false);
    });
  };

  const applyFilter = () => {
    refreshHeatPumpDevices({ ...trimFilters(newFilters), page: 1 });
  };

  const onClickDownloadButton = () => {
    setDownloadingCsv(true);
    dispatch(actions.DownloadCSVAllDevices())
      .then((res) => {
        downloadFile(res.info.download_link, "Devices")
          .then((res) => setDownloadingCsv(false))
          .catch((err) => setDownloadingCsv(false));
      })
      .catch((err) => {
        setDownloadingCsv(false);
      });
  };

  const { t = () => {}, toast, classes } = props;

  const alertWarringMsg = () => {
    setNewFault(false);
    onClickRefresh();
  };

  const resolvedSelected = (data) => {
    dispatch(actions.updatefaultySystem(data))
      .then((res) => {
        refreshHeatPumpDevices([]);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    let tempFilter = {};
    if (location.search) {
      let {
        serialNumberAcc,
        modelNumberAcc,
        faultCodeAcc,
        deviceWifiStatusAcc,
        deviceOperationAcc,
        customerEmailAcc,
        resolvedStatusAcc,
        agencyNameAcc,
        agency_name,
        deviceStatusAcc,
        device_status = [],
        fullNameAcc,
        first_name,
        id,
        idAcc,
        installationDateAcc,
        installedViaAcc,
        installed_via,
        installer_uuid,
        installer_uuidAcc,
        isOpen,
        macAddressAcc,
        mac_address,
        status = [],
        connection_type = [],
        statusAcc,
        limit,
        page,
        sortBy,
        orderBy,
        serial_number,
        soft_version,
        wifi_name,
        firmwareAcc,
        wifiAcc,
        installation_date = [null, null],
      } = { ...qs.parse(location.search, { arrayFormat: "bracket" }) };

      let isStartDateMoment = moment(installation_date && installation_date[0]).isValid();
      let isEndDateMoment = moment(installation_date && installation_date[1]).isValid();

      tempFilter = {
        // agency_name,
        // soft_version,
        // wifi_name,
        // device_status,
        first_name,
        id,
        installed_via,
        installer_uuid,
        isOpen: isOpen === "false" ? false : true,
        mac_address,
        status,
        limit: parseInt(limit),
        page: parseInt(page),
        sortBy,
        orderBy,
        serial_number,
        connection_type,
        installation_date: [
          isStartDateMoment ? new Date(installation_date[0]) : null,
          isEndDateMoment ? new Date(installation_date[1]) : null,
        ],
      };

      let tempAcc = {
        serialNumberAcc: serialNumberAcc === "false" ? false : true,
        modelNumberAcc: modelNumberAcc === "false" ? false : true,
        faultCodeAcc: faultCodeAcc === "false" ? false : true,
        deviceWifiStatusAcc: deviceWifiStatusAcc === "false" ? false : true,
        deviceOperationAcc: deviceOperationAcc === "false" ? false : true,
        customerEmailAcc: customerEmailAcc === "false" ? false : true,
        resolvedStatusAcc: resolvedStatusAcc === "false" ? false : true,
        // firmwareAcc: firmwareAcc === "false" ? false : true,
        // wifiAcc: wifiAcc === "false" ? false : true,
        // agencyNameAcc: agencyNameAcc === "false" ? false : true,
        // deviceStatusAcc: deviceStatusAcc === "false" ? false : true,
        // fullNameAcc: fullNameAcc === "false" ? false : true,
        // idAcc: idAcc === "false" ? false : true,
        // installationDateAcc: installationDateAcc === "false" ? false : true,
        // installedViaAcc: installedViaAcc === "false" ? false : true,
        // installer_uuidAcc: installer_uuidAcc === "false" ? false : true,
        // macAddressAcc: macAddressAcc === "false" ? false : true,
        // statusAcc: statusAcc === "false" ? false : true
      };
      setNewFilters({ ...tempFilter });
      setAccordian({ ...tempAcc });
    }
    refreshHeatPumpDevices(tempFilter);
    return () => {
      // dispatch(actions.clearStoreForDevice())
    };
  }, []);

  useEffect(() => {
    // if (!useRefFaulty?.current) return
    const socket = new WebSocket("wss://tkw0ot6k48.execute-api.ap-southeast-2.amazonaws.com/stage");
    // WebSocket onopen event
    socket.onopen = function (e) {
      console.log("connected to SOCKET");
      dispatch(socketAction.connectToServer(socket));
    };
    socket.onmessage = function (event) {
      let latestData = JSON.parse(event?.data);
      let deviceId = latestData?.event?.find((itm, index) => index === 0)?.device_id;
      let deviceValue = latestData?.event?.find((itm, index) => index === 1);
      let filterRow =
        useRefFaulty?.current &&
        useRefFaulty?.current?.map((item, index) => {
          if (item.device_id == deviceId) {
            item = {
              ...item,
              heatpump: {
                ...item.heatpump,
                device_operation_status:
                  deviceValue?.work_state !== undefined
                    ? deviceValue?.work_state
                    : item?.device_operation_status,
                // device_operation_status: deviceValue?.work_state || item?.heatpump?.device_operation_status,
              },
            };
          }
          return item;
        });

      if (filterRow?.length) {
        setFilteredData(filterRow);
        useRefFaulty.current = filterRow;
      }
      let DashboardData =
        !latestData?.event.length &&
        latestData?.data?.key === "dashboard" &&
        latestData?.data?.value;

      if (!DashboardData?.faulty_count) return;
      setNewFault(true);
    };

    return () => {
      console.log("disconnect device");

      socket.close();
    };
  }, []);

  useEffect(() => {
    let tempFilter = { ...accordion, ...newFilters };

    let {
      status = [],
      device_status = [],
      installation_date = [null, null],
      connection_type = [],
      ...rest
    } = tempFilter;

    let stringRestFilters = qs.stringify({ ...rest });

    let statusInString = qs.stringify({ status: status }, { arrayFormat: "bracket" });

    let deviceStatusInString = qs.stringify(
      { device_status: device_status },
      { arrayFormat: "bracket" }
    );

    let installationDateInString = qs.stringify(
      { installation_date: installation_date },
      { arrayFormat: "bracket" }
    );

    let connectionTypeInString = qs.stringify(
      { connection_type: connection_type },
      { arrayFormat: "bracket" }
    );

    location.search =
      stringRestFilters +
      "&" +
      statusInString +
      "&" +
      deviceStatusInString +
      "&" +
      installationDateInString +
      "&" +
      connectionTypeInString;

    // history.push({
    //     pathname: '/admin/heat_pump/devices',
    //     search: location.search
    // })
  }, [accordion, newFilters]);

  useEffect(() => {
    if (!isLoading) {
      setFilteredData(devices);
      useRefFaulty.current = devices;
    } else {
      // setFilteredData([])
    }
  }, [devices]);

  useEffect(() => {
    if (activeTab) {
      refreshHeatPumpDevices({ ...trimFilters(newFilters), fault_status: activeTab, page: 1 });
    }
  }, [activeTab]);

  return (
    <Grid container className="">
      <Grid item xs={12}>
        <Grid container className="location-head">
          <Grid item xs={12} sm={12} lg={7} md={6} className="heading">
            <h2 className="ht-titletext"> Attention [Heat Pump]</h2>
          </Grid>

          <Grid item xs={12} sm={12} lg={5} md={6}>
            <Box className="search-add">
              {/* <Autocomplete
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                disableClearable
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                size="small"
                                id="controllable-states-demo"
                                options={options}
                                style={{ width: 300 }}
                                renderInput={(params, index) => <TextField key={index} {...params} variant="outlined" />}
                            /> */}
              <IconButton aria-label="filter" onClick={openFilterSidebar}>
                <FilterListIcon />
                <Typography variant="body1" style={{ marginLeft: 8 }}>
                  Search
                </Typography>
              </IconButton>
              <CustomDrawer isOpen={newFilters.isOpen} onClose={onCloseFiltersDrawer}>
                <ul className="list-style-list">
                  <CusBackdrop open={isLoading} className="loader-images" />
                  <li>
                    <CustomAccordion
                      isOpen={accordion.serialNumberAcc}
                      handleChange={handleChangeAccordion("serialNumberAcc", "serial_number")}
                      label={"Fault Devices Number"}>
                      <CustomSearchBar
                        placeholder="Enter Fault Devices Number"
                        value={newFilters.serial_number}
                        onChange={onChangeFilter}
                        name={"serial_number"}
                        onChangeKeyDown={onChangeKeyDown}
                      />
                    </CustomAccordion>
                  </li>
                  <li>
                    <CustomAccordion
                      isOpen={accordion.modelNumberAcc}
                      handleChange={handleChangeAccordion("modelNumberAcc", "model_name")}
                      label={"Model Name"}>
                      <CustomSearchBar
                        placeholder="Enter Model Name"
                        value={newFilters.model_name}
                        onChange={onChangeFilter}
                        name={"model_name"}
                        onChangeKeyDown={onChangeKeyDown}
                      />
                    </CustomAccordion>
                  </li>

                  {/* <li>
                                        <CustomAccordion
                                            isOpen={accordion.installationDateAcc}
                                            handleChange={handleChangeAccordion('installationDateAcc', 'installation_date')}
                                            label={"Date Installed"}
                                        >
                                            <DateRangePickerRsuit
                                                value={newFilters?.installation_date}
                                                onChangeDate={onChangeDate}
                                            />
                                        </CustomAccordion>
                                    </li> */}
                  <li>
                    <CustomAccordion
                      isOpen={accordion.faultCodeAcc}
                      handleChange={handleChangeAccordion("faultCodeAcc", "fault_code")}
                      label={"Fault Code"}>
                      <CustomSearchBar
                        placeholder="Enter Fault Code"
                        value={newFilters.fault_code}
                        onChange={onChangeFilter}
                        name={"fault_code"}
                        onChangeKeyDown={onChangeKeyDown}
                      />
                    </CustomAccordion>
                  </li>

                  <li>
                    <CustomAccordion
                      isOpen={accordion.customerEmailAcc}
                      handleChange={handleChangeAccordion("customerEmailAcc", "email")}
                      label={"Email Address"}>
                      <CustomSearchBar
                        placeholder="Enter Email Address"
                        value={newFilters.email}
                        onChange={onChangeFilter}
                        name={"email"}
                        onChangeKeyDown={onChangeKeyDown}
                      />
                    </CustomAccordion>
                  </li>
                  {/*   <li>
                                        <CustomAccordion
                                            isOpen={accordion.agencyNameAcc}
                                            handleChange={handleChangeAccordion('agencyNameAcc', 'agency_name')}
                                            label={"Agency Name"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Agency Name"
                                                value={newFilters.agency_name}
                                                onChange={onChangeFilter}
                                                name={'agency_name'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.wifiAcc}
                                            handleChange={handleChangeAccordion('wifiAcc', 'wifi_name')}
                                            label={"WiFi Name"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter WiFi Name"
                                                value={newFilters.wifi_name}
                                                onChange={onChangeFilter}
                                                name={'wifi_name'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.firmwareAcc}
                                            handleChange={handleChangeAccordion('firmwareAcc', 'soft_version')}
                                            label={"Firmware Version"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Firmware Version"
                                                value={newFilters.soft_version}
                                                onChange={onChangeFilter}
                                                name={'soft_version'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.fullNameAcc}
                                            handleChange={handleChangeAccordion('fullNameAcc', 'first_name')}
                                            label={"Agent Name"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Agent Name"
                                                value={newFilters.first_name}
                                                onChange={onChangeFilter}
                                                name={'first_name'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.installer_uuidAcc}
                                            handleChange={handleChangeAccordion('installer_uuidAcc', 'installer_uuid')}
                                            label={"Agent ID"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Agent ID"
                                                value={newFilters.installer_uuid}
                                                onChange={onChangeFilter}
                                                name={'installer_uuid'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.idAcc}
                                            handleChange={handleChangeAccordion('idAcc', 'id')}
                                            label={"Record ID"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Record ID"
                                                value={newFilters.id}
                                                onChange={onChangeFilter}
                                                name={'id'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.deviceStatusAcc}
                                            handleChange={handleChangeAccordion('deviceStatusAcc', 'device_status')}
                                            label={"Device Status"}
                                        >
                                            <div
                                                className={`${newFilters?.device_status?.includes('Active') ? 'selected' : "unselected"}`}
                                                onClick={() => onChangeStatus("device_status", "Active")}
                                            >
                                                Active
                                            </div>
                                            <div
                                                className={`${newFilters?.device_status?.includes('In-active') ? 'selected' : "unselected"}`}
                                                onClick={() => onChangeStatus("device_status", "In-active")}
                                            >
                                                In-Active
                                            </div>
                                            <div
                                                className={`${newFilters?.device_status?.includes('Replaced') ? 'selected' : "unselected"}`}
                                                onClick={() => onChangeStatus("device_status", "Replaced")}
                                            >
                                                Replaced
                                            </div>
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.statusAcc}
                                            handleChange={handleChangeAccordion('statusAcc', 'status')}
                                            label={"Customer State"}
                                        >
                                            <ul className="m-0 style-list">
                                                <li>
                                                    <div className={`${newFilters?.status?.includes('Pending') ? 'selected' : "unselected"}`}
                                                        onClick={() => onChangeStatus("status", "Pending")}  >  Pending </div>
                                                </li>
                                                <li>
                                                    <div
                                                        className={`${newFilters?.status?.includes('Completed') ? 'selected' : "unselected"}`}
                                                        onClick={() => onChangeStatus("status", "Completed")}
                                                    >
                                                        Completed
                                                    </div>
                                                </li>
                                                <li>
                                                    <div
                                                        className={`${newFilters?.status?.includes('Incomplete') ? 'selected' : "unselected"}`}
                                                        onClick={() => onChangeStatus("status", "Incomplete")}
                                                    >
                                                        Incomplete
                                                    </div>

                                                </li>
                                                <li>
                                                    <div
                                                        className={`${newFilters?.status?.includes('Deleted') ? 'selected' : "unselected"}`}
                                                        onClick={() => onChangeStatus("status", "Deleted")}
                                                    >
                                                        Deleted
                                                    </div>
                                                </li>
                                            </ul>
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.connetionTypeAcc}
                                            handleChange={handleChangeAccordion('connetionTypeAcc', 'connection_type')}
                                            label={"Connection Type"}
                                        >
                                            <div
                                                className={`${newFilters?.connection_type?.includes('Bluetooth') ? 'selected' : "unselected"}`}
                                                onClick={() => onChangeStatus("connection_type", "Bluetooth")}
                                            >
                                                Bluetooth
                                            </div>
                                            <div
                                                className={`${newFilters?.connection_type?.includes('AP') ? 'selected' : "unselected"}`}
                                                onClick={() => onChangeStatus("connection_type", "AP")}
                                            >
                                                AP
                                            </div>

                                        </CustomAccordion>
                                    </li> */}
                  <li>
                    <SearchFilterButtons
                      clearFilter={clearFilter}
                      applyFilter={applyFilter}
                      isLoading={isLoading}
                      clearFilterLoader={clearFilterLoader}
                    />
                  </li>
                </ul>
              </CustomDrawer>
            </Box>
          </Grid>
        </Grid>
        <Grid container className="alert-msg">
          <Grid item xs={12} sm={12} lg={12} md={12} className="">
            {newFault && (
              <Alert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="medium"
                    onClick={() => alertWarringMsg()}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{
                  width: "100%",
                }}>
                <AlertTitle>Fault Alert</AlertTitle>
                <p>
                  A new heat pump fault is received.Please{" "}
                  <strong>
                    <button className="alert_btn" onClick={() => alertWarringMsg()}>
                      click here{" "}
                    </button>
                  </strong>{" "}
                  to review it.
                </p>
              </Alert>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className="content-container">
        <AppBar position="static" className="bg-white boxshadow-custom tabs-custom-btn">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="tabs"
            variant="fullWidth"
            className=""
            centered>
            <Tab label="Resolved" value="1" />
            <Tab label="Un-Resolved" value="0" />
          </Tabs>
        </AppBar>
        <div id="" className="card-design table-design">
          {/* <Grid item className="searchBar">
                        <TableSearch searchValue={searchValue} placeholder={"Search by device serial number"} changehandler={(value) => {
                            setSearchValue(value)
                            onSearch("serial_number",value)
                            }} />
                    </Grid> */}
          {isLoading ? (
            <>
              <TableContainer className={classes?.container} style={{ overflow: "hidden" }}>
                <Table className="table-program" stickyHeader aria-label="sticky table">
                  <EnhancedTableHead headCells={manageHead} filters={newFilters} />
                </Table>
              </TableContainer>
              <TableLoader />
            </>
          ) : null}
          {!isLoading ? (
            <Grid container direction="column">
              <Grid item>
                {!isLoading && (
                  <SiteTable
                    headers={manageHead}
                    resolvedSelected={resolvedSelected}
                    fillters={newFilters}
                    onPageChange={onPageChange}
                    onChangeLimit={onChangeLimit}
                    total={total}
                    data={filteredData}
                    tableType="faultysystems"
                    onClickRefresh={onClickRefresh}
                    refresh={refresh}
                  />
                )}
              </Grid>
            </Grid>
          ) : null}
          <HeatPumpTablePagination
            count={total || filteredData?.length}
            page={newFilters?.page}
            refresh={refresh}
            onClickRefresh={onClickRefresh}
            filters={newFilters || { page: newFilters?.page, limit: newFilters?.limit }}
            onPageChange={onPageChange}
            onChangeLimit={onChangeLimit}
            //  onChangeGoToPage={onChangeGoToPage}
            //  goToPage={goToPage}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default FaultySystems;
