import { apiGet, apiPost, apiPut, getDeviceType, isLoggedIn } from "../../utils";
import moment from "moment";
const { userType = null } = isLoggedIn();

const generateUrlWithAdminType = (userType, url) => {
  return `/api${userType === "Admin" ? "/" : `/${userType?.toLowerCase()}/`}${url}`;
};

export function getAllMultiSiteDevicesAPI(filters) {
  let arr = [];

  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen") continue;
    if (typeof value !== "number" && !value?.length) continue;

    if (key === "installation_date") {
      if (value[0] == null) continue;
      arr.push({
        key,
        value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
        end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
        type: "single",
      });
    } else if (["device_status", "status"].includes(key)) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value, type: "single" });
    }
  }
  return apiGet(
    `/api/mscc/nms/devices/all?deviceType=${getDeviceType()}&filter=${JSON.stringify(arr)}`
  );
}

export function getMultiSiteSiteList(filters = []) {
  let arr = [];
  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen") continue;
    if (typeof value !== "number" && !value?.length) continue;

    if (["status"].includes(key)) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value, type: "single" });
    }
  }
  return apiGet(
    `${generateUrlWithAdminType(userType, "nms/site/get/all")}?filter=${JSON.stringify(
      arr
    )}&deviceType=${getDeviceType()}`
  );
}

export function assignAddToSiteDevicesApi(data) {
  return apiPost(`${generateUrlWithAdminType(userType, "nms/site/assign-devices")}`, data);
}

export function AddNewMultiSiteSiteApi(data) {
  return apiPost("/api/mscc/nms/site/add", data);
}

export function GetMultiSiteProfileAPI() {
  return apiGet(`/api/mscc/nms/profile/get`);
}

export function EditMultiSiteProfileAPI(data) {
  const formData = new FormData();

  if (data?.password && data?.password?.length) {
    formData.append("password", data.password);
  }

  let flow = data?.flows ? data?.flows?.filter((res) => res) : [];

  formData.append("agency_name", data?.agencyName);
  formData.append("email", data?.email);
  formData.append("postcode", data?.postcode);
  formData.append("contact", data?.contactNo);
  formData.append("country", data?.country);
  formData.append(
    "state",
    (data?.state?.value && data?.state?.value !== null) || data?.state?.value !== undefined
      ? data?.state?.value
      : ""
  );
  formData.append("city", data?.city);
  formData.append("street_address", data?.streetAddress);
  formData.append("plot_address", data?.plotAddress);
  formData.append("contact_first_name", data?.contactFirstName);
  formData.append("contact_last_name", data?.contactLastName);
  formData.append("status", data?.status);
  formData.append("flows", flow.join(","));

  return apiPost("/api/agency/editprofile", formData);
}

export function getSiteByIdAPI(id) {
  return apiGet(`/api/${userType?.toLowerCase()}/nms/site/get/${id}`);
}

export function getSiteDevicesForTableAPI(id) {
  return apiGet(
    `/api/${userType?.toLowerCase()}/nms/site/devices/all?filter=[]&deviceType=heat_pump&site_id=${id}`
  );
  // agency/nms/msc/devices/get/all?filter=[]&multi_site_company_id=d03129ac-1db2-4464-ba99-b6ee63ea0465&deviceType=heat_pump
}
