import React, { useState, useEffect, useCallback } from "react";
import { withStyles } from '@material-ui/core/styles';
import EnhancedTableHead from "../../../../../components/common/EnhancedTableHead";
import CustomTablePagination from "../../../../../components/common/CustomPagination";
import { withTranslation } from "react-i18next";
import { tablestyle, dateWithoutTime, propertyAddressFormat, agentNameFormat, downloadFile, capitalizeFirstLetter, trimFilters } from '../../../../../utils'
import SearchField from "../../../../../components/common/SearchField";
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation, Link } from "react-router-dom"
import _ from "lodash"
import TableLoader from "../../../../../components/common/TableLoader";
import * as actions from './actions';
import MoreText from '../../../../../components/common/TextMore'
import qs from 'query-string'
import CsvDownload from '../../../../../components/common/CsvDownload'
import CusBackdrop from '../../../../../components/common/Backdrop'
import {
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress
} from '@material-ui/core';
import moment from "moment";
import CustomDrawer from "../../../../../components/common/CustomDrawer";
import CustomAccordion from "../../../../../components/common/CustomAccordion";
import CustomSearchBar from "../../../../../components/common/CustomSearchBar";
import DateRangePickerRsuit from "../../../../../components/common/DateRangePicker";
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchFilterButtons from "../../../../../components/common/SearchFilterButtons";

const manageHead = [
    { id: 'serial_number', sortByKey: 'serial_number', label: "Serial Number", className: "show-pointer" },
    { id: 'mac_address', sortByKey: 'mac_address', label: "Mac Address", className: "show-pointer" },
    { id: 'soft_version', sortByKey: 'soft_version', label: "Firmware Version", className: "show-pointer" },
    { id: 'wifi_name', sortByKey: 'wifi_name', label: "WiFi Name", className: "show-pointer" },
    { id: 'postal_code', sortByKey: 'postal_code', label: "Address", className: "show-pointer" },
    { id: 'connection_type', sortByKey: 'connection_type', label: "Connection Type", className: "show-pointer" },
    { id: 'installation_date', sortByKey: 'installation_date', label: "Date Installed", className: "show-pointer" },
    // { id: 'installed_via', sortByKey: 'installed_via', label: "Installed Via", className: "show-pointer" },
    { id: 'agency_name', sortByKey: 'agency_name', label: "Agency Name", className: "show-pointer" },
    { id: 'first_name', sortByKey: 'first_name', label: "Agent Name", className: "show-pointer" },
    // { id: 'installer_uuid', sortByKey: 'installer_uuid', label: "Agent ID", className: "show-pointer" },
    // { id: 'rating', sortByKey: 'rating', label: "Rating", className: "show-pointer" },
    { id: 'status', sortByKey: 'status', label: "Customer State", className: "show-pointer", isMore: true },
    { id: 'Device Status', sortByKey: 'device_status', label: "Device Status", className: "show-pointer", isMore: true },
    { id: 'id', sortByKey: 'id', label: "Record ID", className: "show-pointer" },
    { id: 'action', sortByKey: 'action', label: "Action", stickyClassName: "more" }
];

const initialAccordion = {
    macAddressAcc: false,
    wifiAcc: false,
    firmwareAcc: false,
    installationDateAcc: false,
    installedViaAcc: false,
    agencyNameAcc: false,
    fullNameAcc: false,
    installer_uuidAcc: false,
    deviceStatusAcc: false,
    statusAcc: false,
    idAcc: false
}

const initialFilter = {
    isOpen: false,
    mac_address: '',
    serial_number: "",
    wifi_name: "",
    soft_version: "",
    installed_via: '',
    agency_name: '',
    first_name: '',
    installer_uuid: '',
    device_status: [],
    status: [],
    installation_date: [null, null],
    id: '',
    limit: 25,
    page: 1,
    sortBy: '',
    orderBy: 'desc',
    connection_type: []
}

function UserDevices(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { allHeatPumpDevices = {}, isLoading = false } = useSelector(state => state.heatPumpDevices)
    const { data: devices = [], total = 0 } = allHeatPumpDevices
    const [refresh, setRefresh] = useState(false);
    const [isDownloadingCsv, setDownloadingCsv] = useState(false)
    const [goToPage, setGoToPage] = useState('')
    const [newFilters, setNewFilters] = useState({ ...initialFilter })
    const [accordion, setAccordian] = useState({ ...initialAccordion })
    const [clearFilterLoader, setClearFilterLoader] = useState(false)

    const delaySearch = useCallback(_.debounce(filters => refreshHeatPumpDevices(filters), 500), []);
    const delayGoToPagination = useCallback(_.debounce(filters => goToPageFun(filters), 1000), []);

    useEffect(() => {

        let tempFilter = {}
        if (location.search) {

            let { agencyNameAcc, agency_name, deviceStatusAcc, device_status = [], fullNameAcc, first_name, id, idAcc, installationDateAcc, installedViaAcc,
                installed_via, installer_uuid, installer_uuidAcc, isOpen, macAddressAcc, mac_address, status = [], connection_type = [], statusAcc, limit, page, sortBy, orderBy, serial_number, soft_version, wifi_name, firmwareAcc, wifiAcc,
                installation_date = [null, null]
            } = { ...qs.parse(location.search, { arrayFormat: 'bracket', }) }

            let isStartDateMoment = moment(installation_date && installation_date[0]).isValid();
            let isEndDateMoment = moment(installation_date && installation_date[1]).isValid();

            tempFilter = {
                agency_name,
                soft_version,
                wifi_name,
                device_status,
                first_name,
                id,
                installed_via,
                installer_uuid,
                isOpen: isOpen === "false" ? false : true,
                mac_address,
                status,
                limit: parseInt(limit),
                page: parseInt(page),
                sortBy,
                orderBy,
                serial_number,
                connection_type,
                installation_date: [isStartDateMoment ? new Date(installation_date[0]) : null, isEndDateMoment ? new Date(installation_date[1]) : null]
            }

            let tempAcc = {
                firmwareAcc: firmwareAcc === "false" ? false : true,
                wifiAcc: wifiAcc === "false" ? false : true,
                agencyNameAcc: agencyNameAcc === "false" ? false : true,
                deviceStatusAcc: deviceStatusAcc === "false" ? false : true,
                fullNameAcc: fullNameAcc === "false" ? false : true,
                idAcc: idAcc === "false" ? false : true,
                installationDateAcc: installationDateAcc === "false" ? false : true,
                installedViaAcc: installedViaAcc === "false" ? false : true,
                installer_uuidAcc: installer_uuidAcc === "false" ? false : true,
                macAddressAcc: macAddressAcc === "false" ? false : true,
                statusAcc: statusAcc === "false" ? false : true
            }
            setNewFilters({ ...tempFilter })
            setAccordian({ ...tempAcc })
        }
        refreshHeatPumpDevices(tempFilter)
        return () => {
            // dispatch(actions.clearStoreForDevice())
        }
    }, [])


    useEffect(() => {

        let tempFilter = { ...accordion, ...newFilters }

        let { status = [], device_status = [], installation_date = [null, null], connection_type = [], ...rest } = tempFilter

        let stringRestFilters = qs.stringify({ ...rest })

        let statusInString = qs.stringify({ status: status }, { arrayFormat: 'bracket' });

        let deviceStatusInString = qs.stringify({ device_status: device_status }, { arrayFormat: 'bracket' });

        let installationDateInString = qs.stringify({ installation_date: installation_date }, { arrayFormat: 'bracket' });

        let connectionTypeInString = qs.stringify({ connection_type: connection_type }, { arrayFormat: 'bracket' });

        location.search = stringRestFilters + '&' + statusInString + '&' + deviceStatusInString + '&' + installationDateInString + "&" + connectionTypeInString

        history.push({
            pathname: '/admin/heat_pump/devices',
            search: location.search
        })
    }, [accordion, newFilters])

    const refreshHeatPumpDevices = (filters) => {
        dispatch(actions.getallHeatPumpDevices(filters))
    }

    const goToPageFun = (value) => {
        refreshHeatPumpDevices(value)
    }

    const onChangeDate = (item) => {
        setNewFilters({ ...newFilters, installation_date: item })
        // refreshHeatPumpDevices({ ...newFilters, installation_date: item })
    }

    const onClickRefresh = () => {
        setRefresh(true)
        dispatch(actions.getallHeatPumpDevices(newFilters)).then(() => setRefresh(false)).catch(err => setRefresh(false))
    }

    const onPageChange = (e, page) => {
        refreshHeatPumpDevices({ ...newFilters, page })
        // setfilters({ ...filters, page })
        setNewFilters({ ...newFilters, page })
    }

    const onChangeLimit = (e) => {
        refreshHeatPumpDevices({ ...newFilters, limit: e.target.value, page: 1, sortBy: 'created_at', orderBy: 'desc', })
        setNewFilters({ ...newFilters, limit: e.target.value, page: 1, })
        setGoToPage('')
    }

    const onSortBy = (label) => {
        if (label === "action") return
        let orderBy = ''
        if (newFilters.orderBy === 'desc') {
            orderBy = 'asc'
        } else {
            orderBy = 'desc'
        }
        const filters = { ...newFilters, sortBy: label, orderBy: orderBy }
        setNewFilters(filters)
        refreshHeatPumpDevices(filters)
    }


    const goToPropertyPage = (propertyId) => {
        if (propertyId) {
            history.push(`/admin/customer/property/view/${propertyId}`)
        }
    }
    const onChangeGoToPage = ({ target: { value = '' } }) => {
        if (!value.match(/^[0-9]*$/) || value === "0") return
        let pageValue = Math.ceil(total / newFilters.limit)
        if (value > pageValue) return
        setGoToPage(value ? parseInt(value) : '')
        setNewFilters({ ...newFilters, page: value ? parseInt(value) : '' })
        delayGoToPagination({ ...newFilters, page: value })
    }


    const openFilterSidebar = () => {
        setNewFilters({ ...newFilters, isOpen: true })
    }

    const onCloseFiltersDrawer = () => {
        setNewFilters({ ...newFilters, isOpen: false })
    }

    const handleChangeAccordion = (panel, filterKey) => (event, isExpanded) => {
        if (!isExpanded) {
            if (panel === "installationDateAcc") {
                setNewFilters({ ...newFilters, [filterKey]: [null, null] })
            } else {
                setNewFilters({ ...newFilters, [filterKey]: ['statusAcc', 'deviceStatusAcc'].includes(panel) ? [] : '', page: newFilters[filterKey]?.length ? 1 : newFilters.page })
            }
        }
        setAccordian({ ...accordion, [panel]: isExpanded })
    };

    const onChangeFilter = ({ target: { name = '', value = '' } }) => {
        if (["mac_address", "serial_number"].includes(name) && value) {
            if (!value.match(/^[a-z0-9]+$/i)) return;
        }
        let tempFilter = { ...newFilters }
        tempFilter = { ...tempFilter, [name]: value, page: 1 }
        setNewFilters(tempFilter)
        // delaySearch({ ...tempFilter })
    }

    const onChangeStatus = (key, value) => {
        let tempNewFilter = { ...newFilters }
        if (tempNewFilter[key]?.includes(value)) {
            let index = tempNewFilter[key].indexOf(value)
            tempNewFilter[key]?.splice(index, 1)
        } else {
            tempNewFilter[key]?.push(value)
        }

        tempNewFilter = { ...tempNewFilter, page: 1 }
        setNewFilters(tempNewFilter)
        // refreshHeatPumpDevices(tempNewFilter)
    }

    const onChangeKeyDown = (e) => {
        if (e.key === 'Enter') {
            refreshHeatPumpDevices({ ...trimFilters(newFilters), page: 1 })
        }
    }

    const clearFilter = () => {
        setClearFilterLoader(true)
        let tempFilter = {
            isOpen: newFilters.isOpen,
            mac_address: '',
            connection_type: [],
            device_status: [],
            status: [],
            installation_date: [null, null],
            limit: 25,
            page: 1,
            sortBy: '',
            orderBy: 'desc',
            serial_number: "",
            soft_version: "",
            wifi_name: ""
        }

        setNewFilters({ ...tempFilter })
        setAccordian({ ...initialAccordion })
        dispatch(actions.getallHeatPumpDevices(tempFilter)).then(res => {
            setClearFilterLoader(false)
        })
    }

    const applyFilter = () => {
        refreshHeatPumpDevices({ ...trimFilters(newFilters), page: 1 })
    }

    const onClickDownloadButton = () => {
        setDownloadingCsv(true)
        dispatch(actions.DownloadCSVAllDevices())
            .then(res => {
                downloadFile(res.info.download_link, 'Devices').then(res => setDownloadingCsv(false)).catch(err => setDownloadingCsv(false))
            }).catch(err => {
                setDownloadingCsv(false)
            })
    }


    const { t = () => { }, toast, classes } = props;

    return (
        <Grid container className="location-container" >
            <Grid item xs={12} >
                <Grid container className="location-head">
                    <Grid item xs={12} sm={12} lg={7} md={6} className="heading">
                        <h2> Devices [Heat Pump]</h2>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={5} md={6}>
                        <div className="search-add">
                            <CsvDownload
                                title="Click here to download device's csv "
                                onClickDownloadButton={onClickDownloadButton}
                                isLoading={isDownloadingCsv}
                            />
                            <button className="filter-btn-style" onClick={openFilterSidebar}><img src="/images/filter.svg" /></button>

                            <CustomDrawer isOpen={newFilters.isOpen} onClose={onCloseFiltersDrawer} >
                                <ul className="list-style-list">
                                    <CusBackdrop open={isLoading} className="loader-images" />
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.serialNumberAcc}
                                            handleChange={handleChangeAccordion('serialNumberAcc', 'serial_number')}
                                            label={"Serial Number"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Serial Number"
                                                value={newFilters.serial_number}
                                                onChange={onChangeFilter}
                                                name={'serial_number'}
                                                onChangeKeyDown={onChangeKeyDown}
                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.macAddressAcc}
                                            handleChange={handleChangeAccordion('macAddressAcc', 'mac_address')}
                                            label={"Mac Address"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Mac Address"
                                                value={newFilters.mac_address}
                                                onChange={onChangeFilter}
                                                name={'mac_address'}
                                                onChangeKeyDown={onChangeKeyDown}
                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.installationDateAcc}
                                            handleChange={handleChangeAccordion('installationDateAcc', 'installation_date')}
                                            label={"Date Installed"}
                                        >
                                            <DateRangePickerRsuit
                                                value={newFilters?.installation_date}
                                                onChangeDate={onChangeDate}
                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.installedViaAcc}
                                            handleChange={handleChangeAccordion('installedViaAcc', 'installed_via')}
                                            label={"Installed Via"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Installed Via"
                                                value={newFilters.installed_via}
                                                onChange={onChangeFilter}
                                                name={'installed_via'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.agencyNameAcc}
                                            handleChange={handleChangeAccordion('agencyNameAcc', 'agency_name')}
                                            label={"Agency Name"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Agency Name"
                                                value={newFilters.agency_name}
                                                onChange={onChangeFilter}
                                                name={'agency_name'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.wifiAcc}
                                            handleChange={handleChangeAccordion('wifiAcc', 'wifi_name')}
                                            label={"WiFi Name"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter WiFi Name"
                                                value={newFilters.wifi_name}
                                                onChange={onChangeFilter}
                                                name={'wifi_name'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.firmwareAcc}
                                            handleChange={handleChangeAccordion('firmwareAcc', 'soft_version')}
                                            label={"Firmware Version"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Firmware Version"
                                                value={newFilters.soft_version}
                                                onChange={onChangeFilter}
                                                name={'soft_version'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.fullNameAcc}
                                            handleChange={handleChangeAccordion('fullNameAcc', 'first_name')}
                                            label={"Agent Name"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Agent Name"
                                                value={newFilters.first_name}
                                                onChange={onChangeFilter}
                                                name={'first_name'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.installer_uuidAcc}
                                            handleChange={handleChangeAccordion('installer_uuidAcc', 'installer_uuid')}
                                            label={"Agent ID"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Agent ID"
                                                value={newFilters.installer_uuid}
                                                onChange={onChangeFilter}
                                                name={'installer_uuid'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.idAcc}
                                            handleChange={handleChangeAccordion('idAcc', 'id')}
                                            label={"Record ID"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Record ID"
                                                value={newFilters.id}
                                                onChange={onChangeFilter}
                                                name={'id'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.deviceStatusAcc}
                                            handleChange={handleChangeAccordion('deviceStatusAcc', 'device_status')}
                                            label={"Device Status"}
                                        >
                                            <div
                                                className={`${newFilters?.device_status?.includes('Active') ? 'selected' : "unselected"}`}
                                                onClick={() => onChangeStatus("device_status", "Active")}
                                            >
                                                Active
                                            </div>
                                            <div
                                                className={`${newFilters?.device_status?.includes('In-active') ? 'selected' : "unselected"}`}
                                                onClick={() => onChangeStatus("device_status", "In-active")}
                                            >
                                                In-Active
                                            </div>
                                            <div
                                                className={`${newFilters?.device_status?.includes('Replaced') ? 'selected' : "unselected"}`}
                                                onClick={() => onChangeStatus("device_status", "Replaced")}
                                            >
                                                Replaced
                                            </div>
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.statusAcc}
                                            handleChange={handleChangeAccordion('statusAcc', 'status')}
                                            label={"Customer State"}
                                        >
                                            <ul className="m-0 style-list">
                                                <li>
                                                    <div className={`${newFilters?.status?.includes('Pending') ? 'selected' : "unselected"}`}
                                                        onClick={() => onChangeStatus("status", "Pending")}  >  Pending </div>
                                                </li>
                                                <li>
                                                    <div
                                                        className={`${newFilters?.status?.includes('Completed') ? 'selected' : "unselected"}`}
                                                        onClick={() => onChangeStatus("status", "Completed")}
                                                    >
                                                        Completed
                                                    </div>
                                                </li>
                                                <li>
                                                    <div
                                                        className={`${newFilters?.status?.includes('Incomplete') ? 'selected' : "unselected"}`}
                                                        onClick={() => onChangeStatus("status", "Incomplete")}
                                                    >
                                                        Incomplete
                                                    </div>

                                                </li>
                                                <li>
                                                    <div
                                                        className={`${newFilters?.status?.includes('Deleted') ? 'selected' : "unselected"}`}
                                                        onClick={() => onChangeStatus("status", "Deleted")}
                                                    >
                                                        Deleted
                                                    </div>
                                                </li>
                                            </ul>
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.connetionTypeAcc}
                                            handleChange={handleChangeAccordion('connetionTypeAcc', 'connection_type')}
                                            label={"Connection Type"}
                                        >
                                            <div
                                                className={`${newFilters?.connection_type?.includes('Bluetooth') ? 'selected' : "unselected"}`}
                                                onClick={() => onChangeStatus("connection_type", "Bluetooth")}
                                            >
                                                Bluetooth
                                            </div>
                                            <div
                                                className={`${newFilters?.connection_type?.includes('AP') ? 'selected' : "unselected"}`}
                                                onClick={() => onChangeStatus("connection_type", "AP")}
                                            >
                                                AP
                                            </div>

                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <SearchFilterButtons
                                            clearFilter={clearFilter}
                                            applyFilter={applyFilter}
                                            isLoading={isLoading}
                                            clearFilterLoader={clearFilterLoader}
                                        />
                                    </li>
                                </ul>
                            </CustomDrawer>

                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="content-container mt-4">
                <div id="no-more-tables" className="card-design table-design cf" >
                    {
                        isLoading ?
                            <>
                                <TableContainer className={classes.container} style={{ overflow: "hidden" }}>
                                    <Table className="table-program" stickyHeader aria-label="sticky table">
                                        <EnhancedTableHead
                                            headCells={manageHead}
                                            filters={newFilters}
                                        />
                                    </Table>
                                </TableContainer>
                                <TableLoader />
                            </>
                            : null
                    }
                    {
                        !isLoading ? <TableContainer className={classes.container}>
                            <Table className="table-program" stickyHeader aria-label="sticky table">
                                <EnhancedTableHead
                                    headCells={manageHead}
                                    onSortBy={onSortBy}
                                    filters={newFilters}
                                />
                                <TableBody>
                                    {
                                        devices?.length ? devices.map((item, index) => {
                                            const { unit_number = '', street_number = '', street = '', city = '', state = '', postal_code = '', agency_name = '', connection_type, agent_name = "" } = item

                                            let address = propertyAddressFormat(unit_number, street_number, street, city, state, postal_code)

                                            let { mac_address = '', property_id = '', first_name = '', last_name = '', installer_uuid = '', status = '', id = '', device_status = '', installed_via = '', created_at = '', serial_number = "", soft_version, wifi_name } = item

                                            let statusCSS = status === "Completed" ? "green" : status === "Incomplete" ? "blue" : status === "Pending" ? "yellow" : status === "Deleted" ? "red" : ''

                                            const agentName = agentNameFormat(first_name, last_name)
                                            let devicestatusCSS = device_status === "active" ? "green" : device_status === "Replaced" ? "yellow" : "red"

                                            if (device_status == "In-active") {
                                                device_status = "Inactive"
                                                status = ""
                                                statusCSS = ''
                                            }

                                            return <TableRow key={id} hover role="checkbox" className="cursor_default">
                                                <TableCell className="table-custom-width">{serial_number || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{mac_address?.toUpperCase() || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{soft_version || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{wifi_name || "-"}</TableCell>

                                                <TableCell className="table-custom-width cus-pointer" onClick={() => goToPropertyPage(property_id)}>
                                                    {postal_code ? <p>{address}</p> : <p>No Property Exist</p>}
                                                </TableCell>
                                                <TableCell className="table-custom-width">{connection_type || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{dateWithoutTime(created_at) || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{agency_name || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{agent_name || "-"}</TableCell>

                                                {/* <TableCell className="table-custom-width">{installed_via || "-"}</TableCell>
                                              
                                                <TableCell className="table-custom-width">
                                                    {device_status === "Inactive" ? "-" : installer_uuid ? agentName ? agentName : "-" : 'Installed by customer'}
                                                </TableCell>
                                                <TableCell className="table-custom-width">{installer_uuid || "-"}</TableCell> */}
                                                {/* <TableCell className="table-custom-width">{getRatingFormat(rating) || "-"}</TableCell> */}
                                                <TableCell className={`table-custom-width ${statusCSS}`}>{capitalizeFirstLetter(status) || "-"}</TableCell>
                                                <TableCell className={`table-custom-width ${devicestatusCSS}`}>{capitalizeFirstLetter(device_status) || "-"}</TableCell>
                                                <TableCell className="table-custom-width">  <MoreText data={id} /></TableCell>
                                                <TableCell className="table-custom-width more">
                                                    <Link
                                                        to={`/admin/heat_pump/device/view/${id}${location?.search}`}
                                                        className="link-tag">
                                                        <VisibilityIcon className="show-pointer" />
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        })
                                            :
                                            <TableRow>
                                                <TableCell style={{ border: "none" }} colSpan="10" className="table-no-data">No Data Found</TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer> : null
                    }

                    <CustomTablePagination
                        count={total}
                        refresh={refresh}
                        onClickRefresh={onClickRefresh}
                        filters={newFilters}
                        onPageChange={onPageChange}
                        onChangeLimit={onChangeLimit}
                        onChangeGoToPage={onChangeGoToPage}
                        goToPage={goToPage}
                    />
                </div>

            </Grid>
        </Grid >
    );

}

export default withTranslation("translations")(withStyles(tablestyle)(UserDevices));